import {
 computed, Ref, ComputedRef, unref,
} from 'vue';
import { useAuthStore } from '@/js/store/auth/AuthStore';
import { useUserStore } from '@/js/store/quests/dashboard/UserStore';

type MaybeRef<T> = T | Ref<T> | ComputedRef<T>;

export function useGuildOwnership(guildSlug: MaybeRef<string>) {
    const authStore = useAuthStore();
    const userStore = useUserStore();

    const isGuildOwner = computed(() => {
        if (authStore.isAdmin()) {
            return true;
        }

        const currentGuildSlug = unref(guildSlug);

        return !!userStore.user?.data.owned_guilds.find(
            (ownedGuildSlug) => ownedGuildSlug === currentGuildSlug,
        );
    });

    return {
        isGuildOwner,
    };
}
